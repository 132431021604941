<template>
  <div class="table-broker-menu">
    <div class="container-broker-menu">
      <div class="table-bg-img">
        <img src="../../assets/broker-menu/table-bg-img2.svg" alt="" />
      </div>
      <div class="table-bg-img2">
        <img src="../../assets/broker-menu/table-bg-img.svg" alt="" />
      </div>
      <div class="table-broker-menu-content">
        <table
          id="table-broker-menu"
          style="width: 100%"
          class="table-broker-menu-content-table"
        >
          <tr>
            <th>Brokers</th>
            <th>Platforms</th>
            <th>Regulation</th>
            <th>
              Totel <br />
              Assets
            </th>
            <th>
              Max <br />
              Leverage
            </th>
            <th>
              EUR/USD <br />
              Spread
            </th>
            <th>
              Social <br />
              Trading
            </th>
            <th>
              Customer <br />
              Support
            </th>
            <th>Rankings</th>
            <th></th>
          </tr>

          <tr v-for="(item, index) in tableData" :key="index">
            <td>
              <img :src="`/table-icons/${item.name}.png`" alt="" />
            </td>
            <td v-html="item.platforms"></td>
            <td v-html="item.regulation"></td>
            <td v-html="item.assets"></td>
            <td v-html="item.leverage"></td>
            <td v-html="item.spread"></td>
            <td v-html="item.trading"></td>

            <td class="support-icons-broker-menus">
              <img
                v-if="item.support[0]"
                src="../../assets/broker-menu/phone.svg"
                alt=""
              />
              <img
                v-if="item.support[1]"
                src="../../assets/broker-menu/mail.svg"
                alt=""
              />
              <img
                v-if="item.support[2]"
                src="../../assets/broker-menu/chat.svg"
                alt=""
              />
            </td>

            <td class="td-score">
              <span class="span-rank-broker-menus"
                ><span v-html="item.rank"></span
              ></span>
            </td>
            <td>
              <button
                @click="routeToRegister(item.img, item.name)"
                class="table-btn-broker-menus"
              >
                View More
              </button>
            </td>
          </tr>
        </table>
      </div>

      <div class="table-broker-menu-content-1100">
        <div class="table-broker-menu-content-1100-table">
          <div
            v-for="(item, index) in tableData.slice(0, counter)"
            :key="index"
            class="table-broker-menu-content-1100-table-row"
          >
            <div class="table-broker-menu-content-1100-table-row-img">
              <img :src="`/table-icons/${item.name}.png`" alt="" />
            </div>

            <div class="table-broker-menu-content-1100-table-row-max-leverage">
              <p><strong>Max Leverage:</strong> {{ item.leverage }}</p>
            </div>

            <div class="table-broker-menu-content-1100-table-row-spred">
              <p><strong>EURO/USD Spread:</strong> {{ item.spread }}</p>
            </div>

            <div class="table-broker-menu-content-1100-table-row-show-more">
              <p v-if="!item.showDropDown" @click="showDropDown(item)">
                Expand
                <img
                  class="down-arrow-pink"
                  src="../../assets/broker-menu/pink-arrow.svg"
                  alt=""
                />
              </p>
              <p v-if="item.showDropDown" @click="showDropDown(item)">
                Close
                <img
                  class="up-arrow-pink"
                  src="../../assets/broker-menu/pink-arrow.svg"
                  alt=""
                />
              </p>
            </div>

            <div class="table-broker-menu-content-1100-table-row-rank">
              <span class="span-rank-broker-menus"
                ><span v-html="item.rank"></span
              ></span>
            </div>

            <div class="table-broker-menu-content-1100-table-row-btn">
              <button
                @click="routeToRegister(item.img, item.name, item)"
                class="table-btn-broker-menus"
              >
                View More
              </button>
            </div>

            <transition name="fade">
              <div
                v-if="item.showDropDown"
                class="table-broker-menu-content-1100-table-drop"
              >
                <div>
                  <strong>Platforms:</strong><br />
                  {{ item.platforms }}
                </div>
                <div>
                  <strong>Regulation:</strong><br />
                  {{ item.regulation }}
                </div>
                <div>
                  <strong>Total Assets:</strong><br />
                  {{ item.assets }}
                </div>

                <div>
                  <strong>Social Trading:</strong><br />
                  {{ item.socialTrading }}
                </div>
                <div class="customer-support-1100">
                  <strong>Customer Support:</strong><br />
                  <img
                    v-if="item.support[0]"
                    src="../../assets/broker-menu/phone.svg"
                    alt=""
                  />
                  <img
                    v-if="item.support[1]"
                    src="../../assets/broker-menu/mail.svg"
                    alt=""
                  />
                  <img
                    v-if="item.support[2]"
                    src="../../assets/broker-menu/chat.svg"
                    alt=""
                  />
                </div>
              </div>
            </transition>
          </div>
          <div class="table-broker-menu-content-1100-table-row-load-more">
            <button
              v-if="hideLoadMoreBtn && tableData.length > 4"
              @click="loadMoreBrokers"
            >
              Show More
            </button>
          </div>
        </div>
      </div>

      <div class="table-broker-menu-content-799">
        <div
          v-for="(item, index) in tableData.slice(0, counter)"
          :key="index"
          class="table-broker-menu-content-799-row"
        >
          <div class="table-broker-menu-content-799-row-header">
            <div class="table-broker-menu-content-799-row-img">
              <img :src="`/table-icons/${item.name}.png`" alt="" />
            </div>

            <div class="table-broker-menu-content-799-row-rank">
              <span class="span-rank-broker-menus"
                ><span v-html="item.rank"></span
              ></span>
            </div>
          </div>

          <div class="table-broker-menu-content-799-row-content">
            <div>
              <p><strong>Max Leverage:</strong> {{ item.leverage }}</p>
            </div>
            <div>
              <p><strong>EURO/USD Spread:</strong> {{ item.spread }}</p>
            </div>

            <transition name="fade">
              <div v-if="item.showDropDown">
                <div>
                  <p><strong>Regulation:</strong> {{ item.regulation }}</p>
                </div>
                <div>
                  <p><strong>Platforms:</strong> {{ item.platforms }}</p>
                </div>
                <div>
                  <p><strong>Total Assets:</strong> {{ item.assets }}</p>
                </div>
                <div>
                  <p><strong>Social Trading:</strong> {{ item.trading }}</p>
                </div>
                <div class="customer-support-799-drop">
                  <p>
                    <strong>Customer Support:</strong>
                    <img
                      v-if="item.support[0]"
                      src="../../assets/broker-menu/phone.svg"
                      alt=""
                    />
                    <img
                      v-if="item.support[1]"
                      src="../../assets/broker-menu/mail.svg"
                      alt=""
                    />
                    <img
                      v-if="item.support[2]"
                      src="../../assets/broker-menu/chat.svg"
                      alt=""
                    />
                  </p>
                </div>
              </div>
            </transition>
          </div>

          <div class="table-broker-menu-content-799-row-content-btns">
            <div>
              <p
                class="arrow-p-pink-icon"
                v-if="!item.showDropDown"
                @click="showDropDown(item)"
              >
                Expand
                <img
                  class="down-arrow-pink"
                  src="../../assets/broker-menu/pink-arrow.svg"
                  alt=""
                />
              </p>

              <p
                class="arrow-p-pink-icon"
                v-if="item.showDropDown"
                @click="showDropDown(item)"
              >
                Close
                <img
                  class="up-arrow-pink"
                  src="../../assets/broker-menu/pink-arrow.svg"
                  alt=""
                />
              </p>
            </div>
            <div>
              <button
                @click="routeToRegister(item.img, item.name, item)"
                class="table-btn-broker-menus"
              >
                View More
              </button>
            </div>
          </div>
        </div>
        <div class="table-broker-menu-content-1100-table-row-load-more">
          <button
            v-if="hideLoadMoreBtn && tableData.length > 4"
            @click="loadMoreBrokers"
          >
            Show More
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";
// import fxvc from "../../assets/broker-menu/fxvc.png";
// import europfx from "../../assets/broker-menu/europfx.png";
// import alvexo from "../../assets/broker-menu/alvexo.png";
// import naga from "../../assets/broker-menu/naga.png";
// import fxpn from "../../assets/broker-menu/fxpn.png";
export default {
  data() {
    return {
      counter: 3,
      hideLoadMoreBtn: true,
      tableData: [
        {
          id: 0,
          name: "EuroFx",
          showDropDown: false,
          img: "http://go-dev.leaninvestor.io/assets/europfx.png",
          platforms: "MT4, EuroTrader  2.0, eFXGO!",
          regulation: "CySec  (Authorized by FCA)",
          totalAssets: "194",
          leverage: "1:200",
          spread: "0.0 Pips",
          socialTrading: "Mirror  Trader",
          ranking: "9.7",
          brokerLink: "https://.......",
        },
        {
          id: 1,
          name: "Divexo",
          showDropDown: false,
          img: "http://go-dev.leaninvestor.io/assets/alvexo.png",
          platforms: "MT4, Alvexo Web  Trader, Alvexo  Mobile App",
          regulation: "CySec  (Authorized by FCA)",
          totalAssets: "430",
          leverage: "1:400",
          spread: "from 0.0 Pips",
          socialTrading: "No",
          ranking: "9.6",
          brokerLink: "https://.......",
        },

        {
          id: 2,
          name: "FXVC",
          showDropDown: false,
          img: "http://go-dev.leaninvestor.io/assets/fxvc.png",
          platforms: "FXVC web and Mobile App",
          regulation: "CySec (Authorized by FCA)",
          totalAssets: "250",
          leverage: "1:150",
          spread: "1 Pips",
          socialTrading: "No",
          ranking: "9.2",
          brokerLink: "https://.......",
        },

        {
          id: 3,
          name: "Fxpn",
          showDropDown: false,
          img: "http://go-dev.leaninvestor.io/assets/fxpn.png",
          platforms: "Sirix, MT4",
          regulation: "FCA",
          totalAssets: "362",
          leverage: "1:400",
          spread: "from 0.1 Pips",
          socialTrading: "Yes",
          ranking: "8.7",
          brokerLink: "https://.......",
        },
      ],
    };
  },

  methods: {
    ...mapActions(["initBrokerLogo", "initBrokerName"]),

    getBrokerDataFromApi() {
      this.axios
        .get("https://splitter.trafficon.co/top10?hid=BO96376c86e851d")
        .then((response) => {
          this.tableData = [];
          response.data.forEach((item) => {
            item.showDropDown = false;
            this.tableData.push(item);
          });
        });
    },

    routeToRegister(brokerLogo, brokerName) {
      this.initBrokerLogo(brokerLogo);
      this.initBrokerName(brokerName);
      localStorage.brokerName = brokerName;
      localStorage.brokerEncode = encodeURIComponent(brokerLogo);
      this.$router.push({ path: `/register`, query: this.$route.query });
    },

    showDropDown(item) {
      item.showDropDown = !item.showDropDown;
    },

    loadMoreBrokers() {
      this.counter += 2;
      if (this.counter >= this.tableData.length) {
        this.hideLoadMoreBtn = false;
      }
    },
  },

  created() {
    this.getBrokerDataFromApi();
  },
};
</script>

<style>
.table-broker-menu-content-799 {
  display: none;
}
.table-broker-menu-content-1100 {
  display: none;
}
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
.show-more-add-to-counter span {
  text-align: center;
  color: #e94560;
  display: flex;
  justify-content: center;
  margin-top: 5px;
}
.show-more-add-to-counter {
  margin-top: 15px;
}
.show-more-add-to-counter button {
  cursor: pointer;
  border: 1px solid #e94560;
  width: 280px;
  margin: 0 auto;
  height: 50px;
  color: #e94560;
  background: none;
  border-radius: 50px;
  font-size: 18px;
}

.table-broker-menu-content-table tr th {
  padding-bottom: 15px;
}

.arrow {
  border: solid #e94560;
  border-width: 0 3px 3px 0;
  display: inline-block;
  padding: 3px;
}

.up {
  transform: rotate(-135deg);
  -webkit-transform: rotate(-135deg);
}

.down {
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
}

.container-broker-menu {
  position: relative;
  z-index: 100;
}

.table-broker-menu {
  padding-bottom: 100px;
}
.table-bg-img {
  position: absolute;
  left: -60px;
  top: -60px;
  z-index: -1 !important;
}
.table-bg-img2 {
  position: absolute;
  right: -60px;
  bottom: -60px;
  z-index: -1 !important;
}

table {
  z-index: 1000000;
  border-collapse: collapse;
}

table tr {
  border-bottom: 1px solid rgb(226, 226, 226);
}

table tr:last-child {
  border: 0;
}

.table-broker-menu-content-mb {
  display: none;
}
.table-broker-menu-content {
  box-shadow: 0px 0px 20px 2px rgba(0, 0, 0, 0.1);
  border-radius: 15px;
  margin-top: 120px;
  background: #fff;

  /* padding: 50px 30px; */
}

.table-broker-menu-content-table {
  padding: 20px;
}

.table-broker-menu-content-table tr th {
  font-size: 17px;
  font-weight: 900;
  text-align: left;
  padding: 20px;
}

.table-broker-menu-content-table tr td {
  display: table-cell;
  padding: 20px;
  width: 200px;
  height: 100px;
}
.table-broker-menu-content-table tr td:last-child {
  width: 250px;
}
.table-broker-menu-content-table tr td:nth-child(9) {
  width: 80px;
}

.support-icons-broker-menus img {
  display: inline-block;
  margin: 0 4px;
}
/* .support-icons-broker-menus {
  display: flex !important;
} */

.table-btn-broker-menus {
  background: #e94560;
  color: #fff;
  width: 110px;
  height: 45px;
  font-size: 16px;
  border-radius: 50px;
}

.span-rank-broker-menus {
  background: #3179ff;
  color: #fff;
  display: inline-block;
  font-size: 18px;
  border-radius: 14px;
  height: 40px;
  width: 40px !important;
}

.span-rank-broker-menus span {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 8px;
}

/* /////////////////////////////////////// */
@media (max-width: 1300px) {
  .table-btn-broker-menus {
    width: 120px;
  }
}

@media (max-width: 1200px) {
  .table-broker-menu-content-table tr th {
    font-size: 14px;
  }

  .table-broker-menu-content-table tr td {
    font-size: 14px;
  }
  .table-broker-menu-content-table tr td:first-child img {
    width: 100px;
  }

  .support-icons-broker-menus img {
    width: 20px;
  }
}

@media (max-width: 1115px) {
  .support-icons-broker-menus img {
    width: 18px;
  }
}

@media (max-width: 1100px) {
  .table-broker-menu-content-1100-table-row-load-more {
    margin: 0 auto;
    margin-top: 10px;
    display: flex;
    justify-content: center;
  }
  .table-broker-menu-content-1100-table-row-load-more button {
    border: none;
    background: none;
    color: #e94560;
    font-size: 18px;
    border: 1px solid #e94560;
    width: 280px;
    height: 45px;
    border-radius: 50px;
  }
  .table-broker-menu {
    padding-bottom: 30px;
  }
  .table-broker-menu-content-1100 {
    display: block;
    margin-top: 70px;
  }
  .table-broker-menu-content {
    display: none;
  }
  .table-bg-img {
    top: -45px;
  }

  .table-bg-img2 {
    bottom: 17px;
  }
  .table-broker-menu-content-1100-table {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
  }

  .table-broker-menu-content-1100-table-row {
    width: 100%;
    box-shadow: 0 0 20px 2px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    margin-bottom: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px;
    /* height: 100px; */
    background: #fff;
    flex-wrap: wrap;
  }

  .table-broker-menu-content-1100-table-row div {
    width: 120px;
  }
  /* .table-broker-menu-content-1100-table-row div:last-child {
    width: 180px;
  } */
  .table-broker-menu-content-1100-table-row div:nth-child(5) {
    text-align: center;
  }

  /* .table-btn-broker-menus {
    width: 180px;
  } */

  .table-broker-menu-content-1100-table-row-img img {
    width: 100px;
  }

  .table-broker-menu-content-1100-table-row-show-more p {
    color: #e94560;
    cursor: pointer;
    display: flex;
  }
  .table-broker-menu-content-1100-table-row-show-more p img {
    margin-left: 5px;
    margin-top: 5px;
  }

  .table-broker-menu-content-1100-table-row-show-more p i {
    margin-left: 5px;
  }

  .table-broker-menu-content-1100-table-drop {
    border-top: 2px solid #eee;
    padding: 20px 0;
    margin-top: 20px;
    width: 100% !important;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
  }

  .customer-support-1100 img {
    display: inline-block;
    margin: 0 4px;
    margin-top: 10px;
  }
}

@media (max-width: 1024px) {
  .table-broker-menu-content-1100-table-row div:nth-child(4) {
    text-align: center;
  }
}
@media (max-width: 920px) {
  .table-broker-menu-content-1100-table-row {
    position: relative;
  }
  .table-broker-menu-content-1100-table-row-show-more {
    position: absolute;
    text-align: left;
    bottom: 15px;
    left: 20px;
  }

  .table-broker-menu-content-1100-table-drop div {
    width: 200px;
  }

  .table-broker-menu-content-1100-table-row {
    min-height: 130px;
    height: 100%;
  }
}

@media (max-width: 799px) {
  .table-broker-menu-content-799-row-content-btns p {
    color: #e94560;
  }

  .table-broker-menu-content-799-row-content-btns p i {
    margin-left: 5px;
  }

  .table-broker-menu-content-799-row-content-btns {
    margin-top: 25px;
  }

  .table-broker-menu-content-1100 {
    display: none;
  }

  .table-broker-menu-content-799 {
    margin-top: 50px;
    display: block;
  }

  .table-broker-menu-content-799-row {
    padding: 20px;
    margin-bottom: 20px;
    background: #fff;
    border-radius: 8px;
    box-shadow: 0 0 20px 2px rgba(0, 0, 0, 0.1);
  }

  .table-bg-img2 {
    bottom: -50px;
  }
  .table-bg-img {
    top: -25px;
  }

  .table-broker-menu-content-799-row-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .table-broker-menu-content-799-row-content-btns {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .table-broker-menu-content-799-row-content div {
    margin: 10px 0;
  }

  .customer-support-799-drop img {
    display: inline;
    width: 18px;
    margin: 0 5px;
  }

  .table-btn-broker-menus {
    width: 190px;
  }
}

@media (max-width: 420px) {
  .table-btn-broker-menus {
    width: 150px;
  }
}
@media (max-width: 350px) {
  .table-btn-broker-menus {
    width: 120px;
  }
}

.up-arrow-pink {
  transform: rotate(180deg);
}

.arrow-p-pink-icon {
  display: flex;
}
.arrow-p-pink-icon img {
  margin-top: 5px;
  margin-left: 5px;
}
</style>